import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from './common/api.service';
import { VERIFY_AUTH, PURGE_AUTH, SET_SUPERADMIN, SET_MARKETING } from './store/auth.module';
// import CKEditor from "@ckeditor/ckeditor5-vue";
import CKEditor from 'ckeditor4-vue';
import VueLazyload from 'vue-lazyload';
import Vuelidate from 'vuelidate';
import VeeValidate, { Validator } from 'vee-validate';
import en from 'vee-validate/dist/locale/en';
import jwt_decode from 'jwt-decode';
import VueCurrencyInput from 'vue-currency-input';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'bootstrap';
import 'popper.js';
import 'tooltip.js';
import 'perfect-scrollbar';

// Vue 3rd party plugins
import i18n from './common/plugins/vue-i18n';
import vuetify from './common/plugins/vuetify';
import './common/plugins/bootstrap-vue';
import './common/plugins/perfect-scrollbar';
import './common/plugins/highlight-js';
import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css';
import jwtService from './common/jwt.service';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import './common/mixin';
import { excludes } from './common/global';
// API service init
ApiService.init();

Vue.use(CKEditor);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://assets.vesomayman.vn/resources/images/no-photo.png',
  attempt: 1,
  listenEvents: ['scroll'],
  lazyComponent: true
});
Vue.use(Vuelidate);
const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'USD' }
};
Vue.use(VueCurrencyInput, pluginOptions);
Validator.localize({ en: en });
Vue.use(VeeValidate, { locale: 'en', fieldsBagName: 'vvFields' });
Validator.extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if (jwtService.getToken()) {
    var { account_id, role } = jwt_decode(jwtService.getToken());
    if (role === 'marketing') {
      store.commit(SET_MARKETING, true);
    } else {
      store.commit(SET_MARKETING, false);
    }
    if (account_id) {
      Promise.all([store.dispatch(VERIFY_AUTH, account_id)]).then(next);
      store.commit(SET_SUPERADMIN, excludes[role]);
    }
  } else if (to.meta.ignoreAuth) {
    next();
  } else {
    store.commit(PURGE_AUTH);
    next();
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
